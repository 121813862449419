// import "./About.css";
import "animate.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Holiday1 from "../../../assets/img/reservation.jpg";
// import Holiday3 from "../assets/images/holiday/h-3.jpg";
import "./Holiday.css";

const Holidays = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        infinite: true,
      },
    ],
  };
  return (
    <>
      <section className="service-section mt-5" id="holiday-special">
        <div className="container-fluid container-lg">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="content">
                <div className="section-header white-color">
                  <h2 className="text-white">Special Offers</h2>
                  <h3 className="text-white">Just for you</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center d-none d-lg-flex">
            <div className="col-lg-4 col-md-6">
              <div className="service-box">
                <a href="/order-online">
                  <div className="img">
                    <img src={Holiday1} alt="Offer" />
                  </div>
                </a>
                {/* <div className="content pt-3 px-2">
                  <h4 className="text-center">Order Online</h4>
                  <p className="text-center my-1 masala-common-content">
                    Everyday
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    11:00am-8:00pm
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    $39.99 (plus tax) | $20.99 (kids 5-10, plus tax)
                  </p>
                  <div className="holiday-menu-btn">
                    <a
                      className="onliner-order-desktop mx-auto w-50 mt-4"
                      href={chritmaspdf}
                      target="blank"
                    >
                      Menu
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-box">
                <a href="/order-online">
                  <div className="img">
                    <img src="https://grubdigest.com/images/masala_of_india/item/598882-WhatsApp Image 2025-04-02 at 5.19.33 PM (1).webp" alt="Offer" />
                  </div>
                </a>
                {/* <div className="content pt-3 px-2">
                  <h4 className="text-center">Lunch Buffet</h4>
                  <p className="text-center my-1 masala-common-content">
                    Everyday
                  </p>
                   <p className="text-center my-1 masala-common-content">
                    11am-3pm
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    All you can eat for only $22.99 (Veg & Non-Veg options
                    available)
                  </p>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="service-box">
                <a href="/order-online">
                  <div className="img">
                    <img src={Holiday3} alt="Offer" />
                  </div>
                </a> */}
            {/* <div className="content pt-3 px-2">
                  <h4 className="text-center">Free Delivery</h4>
                  <p className="text-center my-1 masala-common-content">
                    Everyday
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    11:00am-8:00pm
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    $39.99 (plus tax) | $20.99 (kids 5-10, plus tax)
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    Christmas To-go special! Place Orders by December 24th.
                  </p>
                  <div className="holiday-menu-btn">
                    <a
                      className="onliner-order-desktop mx-auto w-50 mt-4"
                      href={chritmaspdf}
                      target="blank"
                    >
                      Menu
                    </a>
                  </div>
                </div> */}
            {/* </div>
            </div> */}
          </div>

          {/* Phone view slider */}
          <Slider
            {...settings}
            className="holidays-slider-dots d-block d-lg-none">
            <div className="px-2 pe-md-2">
              <div className="service-box">
                <a href="/order-online">
                  <div className="img">
                    <img src={Holiday1} alt="Offer" />
                  </div>
                </a>
                {/* <div className="content pt-3 px-2">
                  <h4 className="text-center">Christmas Buffet</h4>
                  <p className="text-center my-1 masala-common-content">
                  Everyday
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    11:00am-8:00pm
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    $39.99 (plus tax) | $20.99 (kids 5-10, plus tax)
                  </p>
                  <div className="holiday-menu-btn">
                    <a
                      className="onliner-order-desktop mx-auto w-50 mt-4"
                      href={chritmaspdf}
                      target="blank"
                    >
                      Menu
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="px-2 pe-md-2">
              <div className="service-box">
                <a href="/order-online">
                  <div className="img">
                    <img src="https://grubdigest.com/images/masala_of_india/item/598882-WhatsApp Image 2025-04-02 at 5.19.33 PM (1).webp" alt="Offer" />
                  </div>
                </a>
                {/* <div className="content pt-3 px-2">
                  <h4 className="text-center">Lunch Buffet</h4>
                  <p className="text-center my-1 masala-common-content">
                    Everyday
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    11am-3pm
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    All you can eat for only $22.99 (Veg & Non-Veg options
                    available)
                  </p>
                </div> */}
              </div>
            </div>
            {/* <div className="px-2 pe-md-2">
              <div className="service-box">
                <a href="/order-online">
                  <div className="img">
                    <img src={Holiday3} alt="Offer" />
                  </div>
                </a> */}
            {/* <div className="content pt-3 px-2">
                  <h4 className="text-center">Christmas to-go Special</h4>
                  <p className="text-center my-1 masala-common-content">
                  Everyday
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    11:00am-8:00pm
                  </p>
                  <p className="text-center my-1 masala-common-content">
                    $39.99 (plus tax) | $20.99 (kids 5-10, plus tax)
                  </p>
                  <div className="holiday-menu-btn">
                    <a
                      className="onliner-order-desktop mx-auto w-50 mt-4"
                      href={chritmaspdf}
                      target="blank"
                    >
                      Menu
                    </a>
                  </div>
                </div> */}
            {/* </div>
            </div> */}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Holidays;

import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import moment from "moment/moment";
import Notiflix from "notiflix";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import reservation from "../../../assets/img/reservation.jpg";
import PostApiCall from "../../../helper/PostAPI";
import "../assets/css/style.css";
import "../Catering/Catering.css";
import Footer from "../Footer/Footer";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import "./ResrvationPage.css";


export default function ResrvationPage() {
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      people: "",
      date: "",
      comments: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required*"),
      phone: Yup.string()
        .matches(/^\d+$/, "Phone number is invalid")
        .required("This field is required*"),
      email: Yup.string()
        .email("Email is Invalid")
        .required("This field is required*"),
      people: Yup.string()
        .matches(/^\d+$/, "Amount of people is invalid")
        .required("This field is required*"),
      date: Yup.date().required("This field is required*"),
    }),
    onSubmit: (values) => {
      const { name, phone, email, people, date, comments } = values;
      sendMail(name, phone, email, people, date, comments);
      formik.resetForm();
    },
  });

  // send mail to the admin
  function sendMail(name, phone, email, people, date, comments) {
    // const formattedDate = moment(date).format("YYYY-MM-DD hh:mm a");  formate 2024-05-21
    // const formattedDate = moment(date).format("MM-DD-YYYY hh:mm a");  formate 05-21-2024
    const formattedDate = moment(date).format("MMMM DD, YYYY hh:mm a"); // formate May 22, 2024 05:03 pm
    Notiflix.Loading.Dots("Please Wait...");
    PostApiCall.postRequest(
      {
        id: null,
        type: "Reservation",
        contactperson: name,
        contactnumber: phone,
        email: email,
        peoplecount: people,
        pickupdate: formattedDate,
        message: comments,
      },
      "reservationenquiry"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Mail sent");
        } else {
          Notiflix.Notify.Failure("Failed");
        }
      })
    );
  }

  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>
          Reservation | Masala of India | Indian Restaurant in Seattle        </title>
        <meta
          name="description"
          content="Experience the vibrant tastes of India at Masala Of India. Reserve your spot today and savor the rich and aromatic spices of Indian cuisine."
        />
        <meta
          name="keywords"
          content="Reservation, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Reservation | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          property="og:description"
          content="Experience the vibrant tastes of India at Masala Of India. Reserve your spot today and savor the rich and aromatic spices of Indian cuisine."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.masalaofindia.com/reservation" />
        <meta
          property="og:image"
          content="  https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Reservation | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          name="twitter:description"
          content="Experience the vibrant tastes of India at Masala Of India. Reserve your spot today and savor the rich and aromatic spices of Indian cuisine."
        />
        <meta
          name="twitter:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta name="twitter:site" content="masala of india" />

        <link rel="canonical" href="https://www.masalaofindia.com/reservation"></link>
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Reservation</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Reservation</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="book-table-section book-reservation-section my-4 my-lg-5">
        <div className="container-md container-fluid px-0 px-3">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="section-header mb-1">
                  {/* <h2>Serve happiness to your guests</h2> */}
                  <h2>Authentic Indian Cuisines</h2>
                  <h3>Unforgettable dining experience</h3>
                  <p>
                    Indulge in the vibrant flavors and aromas of Indian cuisine
                    at Masala Of India Cuisine. Our carefully curated menu
                    offers a delectable array of traditional and contemporary
                    dishes, each prepared with fresh ingredients and authentic
                    spices.
                  </p>
                  <p>
                    Reserve your table today and embark on a culinary journey
                    that will transport you to the heart of India.
                  </p>
                </div>
              </div>

              {/*  ***** Reservation form **** */}
              <div className="row flex-lg-row flex-column-reverse">
                <div className="book-table-box col-lg-7 my-3 mx-auto">
                  {/* <div className="book-table-box col-12 my-3 mx-auto"> */}
                  <div className="book-form">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Full Name *"
                            aria-label=".form-control-lg example"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 col-md-4 mb-4">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Phone Number *"
                            aria-label=".form-control-lg example"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 col-md-4 mb-4">
                          <input
                            className="form-control form-control-lg"
                            type="email"
                            placeholder="Email Address *"
                            aria-label=".form-control-lg example"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 col-md-6 mb-4">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Number of People *"
                            aria-label=".form-control-lg example"
                            name="people"
                            value={formik.values.people}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.people && formik.errors.people ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.people}
                            </div>
                          ) : null}
                        </div>

                        {/* **** show formate in yyyy-mm-dd */}
                        {/* <div className="col-12 col-md-6 mb-4 datepicker-size">
                          <DatePicker
                            placeholder="Date and Time *"
                            showTime={{ format: "hh:mm A" }}
                            className="form-control form-control-lg catering-label"
                            value={
                              formik.values.date
                                ? moment(formik.values.date)
                                : null
                            }
                            onChange={(date, dateString) =>
                              formik.setFieldValue("date", dateString)
                            }
                            onBlur={formik.handleBlur("date")}
                            disabledDate={(current) =>
                              current && current < moment().startOf("day")
                            } // To disable past dates
                          />
                          {formik.touched.date && formik.errors.date ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.date}
                            </div>
                          ) : null}
                        </div> */}

                        {/* **** show formate in mm-dd-yyyy */}
                        <div className="col-12 col-md-6 mb-4 datepicker-size">
                          <DatePicker
                            placeholder="Date and Time *"
                            showTime={{ format: "hh:mm A" }}
                            format="MM-DD-YYYY HH:mm:ss" // Add this line to set the desired date format
                            className="form-control form-control-lg catering-label"
                            value={
                              formik.values.date
                                ? dayjs(formik.values.date)
                                : null
                            }
                            onChange={(date, dateString) =>
                              formik.setFieldValue("date", dateString)
                            }
                            // onBlur={formik.handleBlur("date")}
                            disabledDate={(current) =>
                              current && current < moment().startOf("day")
                            } // To disable past dates
                          />
                          {formik.touched.date && formik.errors.date ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.date}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-12 mb-1">
                          <textarea
                            className="form-control form-control-lg catering-textarea"
                            id="validationTextarea"
                            placeholder="Message"
                            name="comments"
                            value={formik.values.comments}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          ></textarea>
                        </div>

                        <div className="col-lg-12 text-left">
                          <button type="submit" className="custom-button">
                            Reserve Your Table
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5 my-3">
                  <img
                    className="w-100 h-100 mother-day-img-radius"
                    src={reservation}
                    alt="offer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
